import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { TermsOfServiceState, TermsOfService } from '@/store/termsOfService/types';

export const getters: GetterTree<TermsOfServiceState, RootState> = {
  /**
   * check if terms of service needs to be displayed
   *
   * @returns {boolean} true only if features module state has 'features' information
   */
  shouldDisplayTos(state: TermsOfServiceState): boolean {
    return state.display || false;
  },
  /**
   * returns terms of service data
   *
   * @returns {string[]}
   */
  getTermsOfService(state: TermsOfServiceState): TermsOfService {
    return state.terms_of_service || {};
  }
};
