import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { TermsOfServiceState } from '@/store/termsOfService/types';

export const actions: ActionTree<TermsOfServiceState, RootState> = {
  load({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Prepare request
      const url = APIRoute(EP.termsOfService.index);
      // Dispatch request
      axios.get(url).then((response: any) => {
        commit('setTermsOfService', response.data.terms_of_service);
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  },
  acknowledge({ commit, getters }, { termsOfServiceVersion }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.users.accepted_terms_of_service.update);
      const payload = { 
        accepted_terms_of_service: {
          version: termsOfServiceVersion
        }
      };
      axios.put(url, payload).then((response: any) => {
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  }
};
