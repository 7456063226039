import { ObjectId } from '@/store/types';

export interface FeatureState {
  configuration?: Configuration;
  features?: Features;
}

export interface Features {
  allocation_service: string[];
  attributes: FeatureAttributes;
  flags: FeatureFlags[];
  system_modules: string[];
}

export enum FeatureFlags {
  ctr_ipos_heart = "ctr_ipos_heart",
  enable_user_select = "enable_user_select",
  oneid = "oneid",
  bypass_fusionauth = "bypass_fusionauth",
  view_allocation_audit_messages = "view_allocation_audit_messages",
}

export const SystemModules = {
  ALD_PROGRAM_PARTICIPATION: "ald_program_participation",
  BRIDGING_THERAPY: "bridging_therapy",
  CLINICAL_TRANSACTIONS: "clinical_transactions",
  CONSULTATION_SECTION: "consultation_section",
  CONTACT_INFO_ADMINISTRATIVE_REGION: "contact_info_administrative_region",
  CONTACT_INFO_CURRENT_ADDRESS: "contact_info_current_address",
  CONTACT_INFO_RECIPIENT_CONTACT_DETAILS: "contact_info_recipient_contact_details",
  DOWNSTAGING_THERAPY: "downstaging_therapy",
  FINANCIAL_ASSISTANCE: "financial_assistance",
  HCC_CRITERIA: "hcc_criteria",
  HCC_DATA: "hcc_data",
  HEART_MECHANICAL_CIRCULATORY_SUPPORT: "heart_mechanical_circulatory_support",
  HLA_STEM_CELL_THERAPY: "hla_stem_cell_therapy",
  HLA_TYPING_INCOMPLETE: "hla_typing_incomplete",
  HLA_TYPING_MOLECULAR_GROUP: "hla_typing_molecular_group",
  HLA_TYPING_MOLECULAR_OVERRIDE: "hla_typing_molecular_override",
  HLA_TYPING_MOST_LIKELY_ALLELE: "hla_typing_most_likely_allele",
  HLA_TYPING_SUBMIT_DATE_EDITABLE: "hla_typing_submit_date_editable",
  KIDNEY_DIALYSIS_EXTENDED_DETAILS: "kidney_dialysis_extended_details",
  KIDNEY_GENERAL_EXTENDED_DETAILS: "kidney_general_extended_details",
  KIDNEY_PAEDIATRIC_AGE_OVERRIDE: "kidney_paediatric_age_override",
  LAB_LIVER_PELD: "lab_liver_peld",
  MEASUREMENTS_HEART_HEMODYNAMIC: "measurements_heart_hemodynamic",
  MEASUREMENTS_HEART: "measurements_heart",
  MEDICAL_ASSESSMENT_SECTION: "medical_assessment_section",
  PHYSICIAN_DETAILS: "physician_details",
  POLICY_EXEMPTIONS: "policy_exemptions",
  PROCEDURE_VAD: "procedure_vad",
  PROTOTYPES: "prototypes",
  RECIPIENT_DEATH_EXTENDED_DETAILS: "recipient_death_extended_details",
  RECIPIENT_DEATH_RELATED_TO_WAITTIME: "recipient_death_related_to_waittime",
  RECIPIENT_OOP_JOURNEYS: "recipient_oop_journeys",
  REFERRAL_DECISION: "referral_decision",
  REFERRAL_DETAILS_EXTENDED_DETAILS: "referral_details_extended_details",
  REFERRED_WITH: "referred_with",
  THORACIC_MEASUREMENTS: "thoracic_measurements",
  TRANSFER_ACTIVE: "transfer_active",
  TRANSFER_POST_TRANSPLANT: "transfer_post_transplant",
  URGENT_LISTING: "urgent_listing",
  URGENT_RECIPIENT: "urgent_recipient",
  LIVER_EXCEPTION_DISEASES: "liver_exception_diseases",
  KDPI: "kdpi"
};

export interface FeatureAttributes {
  default_locale?: string;
  enabled_locales?: string[];
  date_format?: string;
  help_url?: string;
  application_name_prefix?: string;
}

export interface Configuration {
  client?: string;
  regions?: string[];
  logo?: string;
  footer_logo?: string;
  base_class?: string;
  login_provider?: string;
}

export interface ObjectWithLocation {
  location?: {
    province_code?: string
  }
}
