
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from "vuex-class";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IdLookup } from '@/store/validations/types';
import { ObjectId, GenericCodeValue } from '@/store/types';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import DonationDestination from '@/components/livingDonors/DonationDestination.vue';
import { LivingDonor, LivingDonorOrgan, LivingDonorJourney } from '@/store/livingDonors/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TextInput from "@/components/shared/TextInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { hospitals } from "@/store/hospitals";

interface OrganConsent {
  organValue: string;
  organCode: number,
  consentDate?: string|null,
  consented: boolean
}
interface DonationInformationForm {
  transplantProgram?: string|null;
  livingDonorCoordinator?: string|null;
  transplantProgramMrn?: string|null
  organConsents: OrganConsent[]
}

@Component({
  components: {
    DateInput,
    SubSection,
    CardSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    DonationDestination,
  }
})
export default class DonationInformation extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.donationInformation) editState!: DonationInformationForm;
  @State(state => state.lookups.organ) organLookup!: Organ[];

  // Getters
  @Getter('show', { namespace: 'livingDonors' }) private livingDonor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: CoordinatorOptions[];
  @Getter('ontarioTransplantOptions', { namespace: 'hospitals' }) ontarioTransplantOptions!: GenericCodeValue[];
  @Getter('getOrganCodesByHospitalId', { namespace: 'hospitals' }) getOrganCodesByHospitalId!: (hospitalId?: string|null) => string[];
  @Getter('getUserOrganCodes', { namespace: 'users' }) getUserOrganCodes!: number[];
  @Getter('getUserHospitalIds', { namespace: 'users' }) getUserHospitalIds!: string[];
  @Getter('getUserHospitalOrganCodesByHospitalId', { namespace: 'users' }) getUserHospitalOrganCodesByHospitalId!: (hospitalId?: string|null) => number[];

  // Properties
  @Prop({ default: false }) newLivingDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [];
  public isLoadingCoordinators = false;

  organConsentChanged(organ: OrganConsent, index: number): void {
    if (organ && organ.consented == false) {
      Vue.set(this.editState.organConsents[index], 'consented', false);
      Vue.set(this.editState.organConsents[index], 'consentDate', null);
    }
  }

  /**
   * Returns true/false based on comparing organ code against Transplant Program and User's Organ Code permissions
   *
   * @param {number} organ_code as a number
   * @returns {boolean} based on Transplant Program
   */

  organCodesAllowedByTransplantProgram(organ_code: number): boolean {
    const hospital = this.editState.transplantProgram;
    const organCodes = this.getOrganCodesByHospitalId(hospital) || [];
    const hospitalHasOrganCode = organCodes ? organCodes.includes(organ_code.toString()) : false;
    if (!hospitalHasOrganCode) return false;

    // check user belongs to selected transplant program
    const userBelongsToHospital = this.getUserHospitalIds.find((hospitalId) => {
      return hospitalId == hospital ? true :false;
    });
    if (!userBelongsToHospital) return false;

    //if so check the user allowed hospital has organ codes
    return this.getUserHospitalOrganCodesByHospitalId(hospital) ? this.getUserHospitalOrganCodesByHospitalId(hospital).includes(organ_code) : false;
  }

  /**
   * Return organs available for Living Donor (Liver/Kidney/Lung)
   *
   * @returns {Organ[]} filtered list of Organs
   */
  get livingDonorOrgans(): Organ[] {
    return this.organLookup.filter((organ: Organ) => {
      return [OrganCodeValue.Liver, OrganCodeValue.Kidney, OrganCodeValue.Lung].includes(organ.code);
    });
  }

  /**
   * Return array of living donor's consented organs
   *
   * @param {string} organValue as a string
   * @returns {boolean} true if we should disable
   */
  get getConsentedOrganCodes(): any {
    const consentedOrgans: any = this.editState.organConsents.filter((o) => {
      return o.consented;
    }).map((o) => { return o.organCode; });
    return consentedOrgans;
  }

  /**
   * Return boolean to disable organ_consents except for the one selected
   *
   * Living Donor can only select one organ_constent
   *
   * @param {string} organValue as a string
   * @returns {boolean} true if we should disable
   */
  public disableOrganConsent(organValue: string): boolean {
    const consentedOrgan: any = this.editState.organConsents.find((o) => {
      return o.consented;
    });

    return consentedOrgan && consentedOrgan.organValue !== organValue;
  }

  // Triggered when all the lookups have been loaded
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'donationInformation');
  }

  // Build editable form state and commit it to vue-x page state module
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'donationInformation',
      value: this.buildDonationInformationForm(this.newLivingDonor ? undefined : this.livingDonor)
    });
  }

  public resetCoordinatorsList(hospitalId: string) {
    // reset coordinator
    Vue.set(this.editState, 'livingDonorCoordinator', undefined);
    // filter list
    this.filterCoordinators(hospitalId);
  }

  // Given a hospital, load all coordinators for that hospital
  public filterCoordinators(hospitalId: string) {
    if (hospitalId) {
      this.isLoadingCoordinators = true;
      this.$store.dispatch('coordinators/loadLivingDonorCoordinators', { hospitalId }).then(() => {
        this.isLoadingCoordinators = false;
      });
    } else {
      Vue.set(this.editState, 'livingDonorCoordinator', undefined);
    }
  }

  /**
   * Generates Donation Information form state based on a Living Donor document
   *
   * @param livingDonor Living Donor document provided by API
   * @returns {DonationInformationForm} Donation Information form state
   */
  public buildDonationInformationForm(livingDonor?: LivingDonor): DonationInformationForm {
    // TODO: Add in hospital and coordinator from the journey

    let activeJourney: any = null;
    if(livingDonor
      && livingDonor.living_donor_info
      && livingDonor.living_donor_info.journeys
      && livingDonor.living_donor_info.journeys.length > 0) {
      activeJourney = livingDonor.living_donor_info.journeys[0];
    }
    let transplantProgram = null;
    let livingDonorCoordinator = null;
    let transplantProgramMrn = null;
    if(activeJourney && activeJourney.transplant_program ) {
      if(activeJourney.transplant_program.transplant_hospital_id) {
        transplantProgram = activeJourney.transplant_program.transplant_hospital_id.$oid;
        this.filterCoordinators(transplantProgram);
      }

      if(activeJourney.transplant_program.living_donor_coordinator_id) {
        livingDonorCoordinator = activeJourney.transplant_program.living_donor_coordinator_id.$oid;
      }

      if(activeJourney.transplant_program.transplant_hospital_mrn) {
        transplantProgramMrn = activeJourney.transplant_program.transplant_hospital_mrn;
      }
    }

    const organConsents: OrganConsent[] = this.livingDonorOrgans.map((o) => {
      return {
        organValue: o.value,
        organCode: o.code,
        consented: activeJourney && activeJourney.organ_code === o.code,
        consentDate: activeJourney && activeJourney.organ_code === o.code ? this.parseDateUi(activeJourney.start_date) : null
      };
    });

    return {
      transplantProgram: transplantProgram,
      livingDonorCoordinator: livingDonorCoordinator,
      transplantProgramMrn: transplantProgramMrn,
      organConsents: organConsents
    };
  }

  public extractPatch(): LivingDonor {
    const coordinatorId: ObjectId|null = this.editState.livingDonorCoordinator ? { $oid: this.editState.livingDonorCoordinator } : null;
    const hospitalId: ObjectId|null = this.editState.transplantProgram ? { $oid: this.editState.transplantProgram } : null;

    const consentedOrgan = this.editState.organConsents.find((organ: LivingDonorOrgan) => {
      return organ.consented;
    });
    const organCode = consentedOrgan ? consentedOrgan.organCode : null;
    const startDate = consentedOrgan && consentedOrgan.consentDate ? consentedOrgan.consentDate : '';
    
    
    return {
      living_donor_info: {
        journeys: [{
          organ_code: organCode || undefined,
          start_date: startDate,
          transplant_program: {
            transplant_hospital_id: hospitalId || null,
            living_donor_coordinator_id: coordinatorId || null,
            transplant_hospital_mrn: this.editState.transplantProgramMrn || null,
          }
        }]
      }
    };
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveDonationInformation as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'donationInformation');
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store
      .dispatch('livingDonors/saveLivingDonor', {
        livingDonorId: this.livingDonorId,
        livingDonor: livingDonorPatch
      }).then((success: SaveResult) => {
        // If successful, reload living donor and show success notification
        this.$emit('reload');
        saveProvider.registerSaveResult(success);
      }).catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveDonationInformation as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  private clear() {
    this.$emit('clear');
  }

  private reload() {
    this.$emit('reload');
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // Define validation keys for this card-section component
    const result = {
      'living_donor_info.journeys.transplant_program.living_donor_coordinator_id'     : 'ld-transplant-coordinator',
      'living_donor_info.journeys.transplant_program.transplant_hospital_id'          : 'ld-transplant-program',
      'living_donor_info.journeys[1.0].start_date'                                    : 'organ-1-consented-date',
      'living_donor_info.journeys[3.0].start_date'                                    : 'organ-3-consented-date',
      'living_donor_info.journeys[4.0].start_date'                                    : 'organ-4-consented-date',
      'living_donor_info.journeys[0].organ_code'                                      : 'organ-1-checkbox',
    };

    // Include vadlidation keys from nested sub-sections
    const donationDestination = this.$refs.donationDestination as DonationDestination;
    if (donationDestination) Object.assign(result, { ...donationDestination.idLookup() });

    return result;
  }

  // Re-initialize this card section and its nested sub sections
  public reinitialize(): void {
    // This card section
    this.initializeForm();

    // Nested sub-sections
    const donationDestination = this.$refs.donationDestination as DonationDestination;
    if (donationDestination) donationDestination.reinitialize();
  }
}
