import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/termsOfService/actions';
import { getters } from '@/store/termsOfService/getters';
import { mutations } from '@/store/termsOfService/mutations';
import { TermsOfServiceState } from '@/store/termsOfService/types';

const state: TermsOfServiceState = {};

const namespaced = true;

export const termsOfService: Module<TermsOfServiceState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced,
};
