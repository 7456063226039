
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SideNavProfile from '@/components/administration/side-nav/SideNavProfile.vue';
import { TermsOfService } from '@/store/termsOfService/types';

@Component({
  components: {
    SideNavProfile
  }
})
export default class TermsOfServicePage extends Vue {
  @Getter('getTermsOfService', { namespace: 'termsOfService' }) private termsOfService!: TermsOfService;

  get tosContent(): string {
    return this.termsOfService?.html_content || '';
  }

}
