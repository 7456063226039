
import { Component, Vue } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';

@Component
export default class SiteFooter extends Vue {
  @Getter('getFooterLogo', { namespace: 'features' }) private getFooterLogo!: string;
  
  get currentYear() {
    return new Date().getFullYear();
  }
}
