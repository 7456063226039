
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LoginContainer extends Vue {
  @State(state => state.uiEnvVersion) private uiEnvVersion!: string|null;

  @Getter('getLogo', { namespace: 'features' }) private getLogo!: string;
  @Getter('getFooterLogo', { namespace: 'features' }) private getFooterLogo!: string;
}
