
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import { TermsOfService } from '@/store/termsOfService/types';

@Component({
  components: {
    ModalSection
  }
})
export default class TermsOfServiceModal extends Vue {
  @Getter('getTermsOfService', { namespace: 'termsOfService' }) private termsOfService!: TermsOfService;

  public initializeModal(): void {
    const targetModal = this.$refs.termsOfServiceModal as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.termsOfServiceModal as ModalSection;
    targetModal.hideModal();
  }

  get tosVersion(): string {
    return this.termsOfService?.version || '';
  }

  get tosContent(): string {
    return this.termsOfService?.html_content || '';
  }

  get actionContent(): string {
    return this.termsOfService?.action_content || '';
  }

   // Acknowledging the Terms of Service
   private acknowledgeTOS(): void {
    // Dispatch acknowledge action and show response
    this.$store.dispatch('termsOfService/acknowledge', { termsOfServiceVersion: this.tosVersion }).then(() => {
      this.closeModal();
    });
  }

  public toggleDeclineModal(declining: boolean): void {
    if (declining) {
      document.getElementById('terms-of-service-modal')?.classList.add('declining-modal-open');
    } else {
      document.getElementById('terms-of-service-modal')?.classList.remove('declining-modal-open');
    }
  }

  private openDeclineModal(): void{
    this.toggleDeclineModal(true)
    this.$emit("openDeclineModal");
  }
}
