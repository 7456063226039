
import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import { TermsOfService } from '@/store/termsOfService/types';
import store from '@/store';
import { APIBaseRoute, EP } from '@/api-endpoints';

@Component({
  components: {
    ModalSection
  }
})
export default class DeclineTermsOfServiceModal extends Vue {
  @Getter('getTermsOfService', { namespace: 'termsOfService' }) private termsOfService!: TermsOfService;
  @Getter('bypassFusionAuth', { namespace: 'features' }) private bypassFusionAuth!: boolean;

  public initializeModal(): void {
    const targetModal = this.$refs.termsOfServiceDeclineModal as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Close modal
  private closeDeclineModal(): void {
    const targetModal = this.$refs.termsOfServiceDeclineModal as ModalSection;
    targetModal.hideModal();
    this.$emit("closeDeclineModal");
  }

  // emits closeDeclineModal on hide event to ungrey out the Main ToS Modal.
  private modalEvent(options: any) {
    this.$emit("closeDeclineModal");
  }

  get tosVersion(): string {
    return this.termsOfService?.version || '';
  }

   // Acknowledging the Terms of Service
   private logout(): void {
    store.dispatch('users/logout').then(() => {
      // if fusionauth enabled and access token exists, redirect to user list page
      if (!this.bypassFusionAuth && localStorage.getItem('access_token')) {
        const access_token = localStorage.getItem('access_token') || '';
        const url = APIBaseRoute(EP.fusionAuth.access_token);      
        window.location.href = url;
      // otherwise login page
      } else {
        window.location.href = "/login";
      }
    });
  }
}
