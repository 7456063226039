import Vue from 'vue';
import { MutationTree } from 'vuex';
import { TermsOfServiceState } from '@/store/termsOfService/types';

export const mutations: MutationTree<TermsOfServiceState> = {
  setTermsOfService(state: TermsOfServiceState, opts) {
    Vue.set(state, 'terms_of_service', opts.terms_of_service);
    Vue.set(state, 'display', opts.display);
  }
};
